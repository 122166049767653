import React from "react";
import styles from "../../BulkUpload.module.scss";
// import { isCurrUserAdmin } from "../../../../../utils/clientStorageUtils";
// import TableRow from "./TableRow";
import { utils, writeFile } from "xlsx-js-style";
import { Button } from "@mui/material";
import { errorRowUtils } from "../../utils";

const Table = ({
  tableData,
  changeEditStatus,
  logoUpload,
  removeImage,
  variantsData,
  categories,
  countriesList,
  measuringUnits,
}: any) => {
  // const massageTableData = (tableObj: any) => {
  //   if (tableObj && tableObj.is_deactivated) {
  //     const deactivateValue = tableObj.is_deactivated.value;
  //     if (
  //       deactivateValue === null ||
  //       deactivateValue === undefined ||
  //       deactivateValue === ""
  //     ) {
  //       tableObj.is_deactivated.value = false;
  //       return tableObj;
  //     }
  //     if (deactivateValue === true || deactivateValue === false) {
  //       return tableObj;
  //     }
  //     if (String(deactivateValue).toLowerCase() === "true") {
  //       tableObj.is_deactivated.value = true;
  //       return tableObj;
  //     } else {
  //       tableObj.is_deactivated.value = false;
  //       return tableObj;
  //     }
  //   }
  // };

  const downloadExcel = () => {
    // Create a new workbook and a worksheet
    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet([]);

    const headers = [
      "Product_variant_title",
      "Master_watermelon_product_code",
      "Supplier_product_code",
      "Product_option1_ordering_unit_value",
      "Product_option1_ordering_unit_label",
      "Product_option1_secondary_ordering_unit_value",
      "Product_option1_secondary_ordering_unit_label",
      "Product_option1_unit_price",
      "Product_option1_offer_percentage",
      "Product_option1_min_quantity",
      "Product_option2_ordering_unit_value",
      "Product_option2_ordering_unit_label",
      "Product_option2_secondary_ordering_unit_value",
      "Product_option2_secondary_ordering_unit_label",
      "Product_option2_unit_price",
      "Product_option2_offer_percentage",
      "Product_option2_min_quantity",
      "Product_option3_ordering_unit_value",
      "Product_option3_ordering_unit_label",
      "Product_option3_secondary_ordering_unit_value",
      "Product_option3_secondary_ordering_unit_label",
      "Product_option3_unit_price",
      "Product_option3_offer_percentage",
      "Product_option3_min_quantity",
      "Description",
      "Brand",
      "Certifications",
      "Specifications",
      "Tags",
      "Category_name",
      "Sub_category_name",
      "Sub_sub_category_name",
      "Is_deactivated",
      "Country",
      "Hs_code",
      "Is_vat_included",
      "Default_image",
      "Gallery_image_1",
      "Gallery_image_2",
      "Gallery_image_3",
      "Gallery_image_4",
      "Size",
      "Flavor",
      "Capacity",
      "Color",
      "Scent",
      "Origin",
      "Material",
      "Type",
      "Cut or form",
      "master_product_id",
      "product_id",
      "variant_id_1",
      "variant_id_2",
      "variant_id_3",
      "image_id_1",
      "image_id_2",
      "image_id_3",
      "image_id_4",
      "image_id_5",
    ];
    ws["!cols"] = headers.map((h) => {
      if (h === "Product_variant_title") {
        return { wch: 40 };
      }
      return { wch: 15 };
    });

    if (!ws["!rows"]) ws["!rows"] = [];
    // Set row heights
    utils.sheet_add_aoa(ws, [headers]);

    const customerKeys = [
      "title",
      "master_watermelon_product_code",
      "supplier_product_code",
      "ordering_option_value_1",
      "ordering_option_label_1",
      "secondary_ordering_option_value_1",
      "secondary_ordering_option_label_1",
      "unit_price_1",
      "offer_1",
      "min_quantity_1",
      "ordering_option_value_2",
      "ordering_option_label_2",
      "secondary_ordering_option_value_2",
      "secondary_ordering_option_label_2",
      "unit_price_2",
      "offer_2",
      "min_quantity_2",
      "ordering_option_value_3",
      "ordering_option_label_3",
      "secondary_ordering_option_value_3",
      "secondary_ordering_option_label_3",
      "unit_price_3",
      "offer_3",
      "min_quantity_3",
      "description",
      "brand",
      "certifications",
      "specifications",
      "tags",
      "category",
      "sub_category",
      "sub_sub_category",
      "is_deactivated",
      "country",
      "hs_code",
      "is_vat_included",
      "image_1",
      "gallery_image_1",
      "gallery_image_2",
      "gallery_image_3",
      "gallery_image_4",
      "size",
      "flavor",
      "capacity",
      "color",
      "scent",
      "origin",
      "material",
      "type",
      "cutorform",
      "master_product_id",
      "product_id",
      "variant_id_1",
      "variant_id_2",
      "variant_id_3",
      "image_id_1",
      "image_id_2",
      "image_id_3",
      "image_id_4",
      "image_id_5",
    ];

    const data = tableData.map((customer: any) =>
      customerKeys.map((key) => customer[key]?.value)
    );

    utils.sheet_add_aoa(ws, data, { origin: "A2" });

    // Set worksheet range
    const range = {
      s: { c: 0, r: 0 }, // Start at A1
      e: { c: headers.length - 1, r: data.length },
    };
    ws["!ref"] = utils.encode_range(range);

    data.forEach((row: any[], rowIndex: number) => {
      if (!errorRowUtils(tableData[rowIndex], rowIndex)) {
        return;
      }
      row.forEach((cell: any, colIndex: number) => {
        const property = customerKeys[colIndex];
        const propertyToCheck = [
          "title",
          "category",
          "sub_category",
          "sub_sub_category",
          "country",
          "description",
          "image_1",
          "unit_price_1",
          "min_quantity_1",
          "ordering_option_value_1",
          "ordering_option_label_1",
          "secondary_ordering_option_value_1",
          "secondary_ordering_option_label_1",
        ];
        const cellRef = utils.encode_cell({ r: rowIndex + 1, c: colIndex });

        ws["!rows"]![rowIndex + 1] = { hpt: 60 }; // height in points
        if (!propertyToCheck.includes(property)) {
          ws[cellRef] = {
            ...ws[cellRef],
            s: { fill: { fgColor: { rgb: "f9e0e0" } } },
          };
          return;
        }

        ws[cellRef] = {
          ...ws[cellRef],
          v: cell ?? "missing",
          s: cell
            ? { fill: { fgColor: { rgb: "f9e0e0" } } }
            : { fill: { fgColor: { rgb: "d9534f" } } },
        };
      });
    });

    utils.book_append_sheet(wb, ws, "Catalogue Errors");

    // Write the workbook to a file
    writeFile(wb, "ValidatedCatalogue.xlsx", {
      cellStyles: true,
    });
  };

  // const doesHaveDeactivatedColumn = () => {
  //   return (
  //     tableData &&
  //     tableData.length > 0 &&
  //     tableData[0].hasOwnProperty("is_deactivated")
  //   );
  // };
  return (
    <div className={styles.tableParent}>
      {/* {tableData.length < 100 ? (
        <table>
          <thead className={styles.theading}>
            <tr>
              <th>
                Product variant title <span className={styles.required}>*</span>
              </th>
              {!isCurrUserAdmin() && <th>Master watermelon product code</th>}
              <th>Description</th>

              <th>Brand</th>
              <th>Certifications</th>
              <th>Specifications</th>
              <th>Tags</th>

              <th>
                Category name <span className={styles.required}>*</span>
              </th>
              <th>
                Subcategory name <span className={styles.required}>*</span>
              </th>
              <th>
                Sub subcategory name <span className={styles.required}>*</span>
              </th>
              <th>
                Country <span className={styles.required}>*</span>
              </th>
              <th>Supplier product code</th>
              <th>HS code</th>
              <th>Is vat included</th>

              <th>
                Default image <span className={styles.required}>*</span>
              </th>

              <th>Gallery (Max: 4)</th>

              <th>Size</th>
              <th>Flavor</th>
              <th>Capacity</th>
              <th>Color</th>
              <th>Scent</th>
              <th>Origin</th>
              <th>Material</th>
              <th>Type</th>
              <th>Cut or from</th>
              {doesHaveDeactivatedColumn() && <th>Is product deactivated</th>}
              {variantsData &&
                variantsData?.length > 0 &&
                variantsData?.map((variant: any, variantIndex: number) => (
                  <React.Fragment key={variantIndex}>
                    <th>
                      {`Product option${variantIndex + 1} ordering unit value`}
                      {variantIndex === 0 && (
                        <span className={styles.required}>*</span>
                      )}
                    </th>
                    <th>
                      {`Product option${variantIndex + 1} ordering unit label`}
                      {variantIndex === 0 && (
                        <span className={styles.required}>*</span>
                      )}
                    </th>
                    <th>
                      {`Product option${
                        variantIndex + 1
                      } secondary ordering unit value`}
                      {variantIndex === 0 && (
                        <span className={styles.required}>*</span>
                      )}
                    </th>
                    <th>
                      {`Product option${
                        variantIndex + 1
                      } secondary ordering unit label`}
                      {variantIndex === 0 && (
                        <span className={styles.required}>*</span>
                      )}
                    </th>
                    <th>
                      {`Product option${variantIndex + 1} unit price`}
                      {variantIndex === 0 && (
                        <span className={styles.required}>*</span>
                      )}
                    </th>
                    <th>
                      {`Product option${variantIndex + 1} offer`} (0 to 99)
                    </th>

                    <th>
                      {`Product option${variantIndex + 1} min quantity`}
                      {variantIndex === 0 && (
                        <span className={styles.required}>*</span>
                      )}
                    </th>
                  </React.Fragment>
                ))}
            </tr>
          </thead>
          <tbody>
            {tableData &&
              tableData.map((customer: any, i: any) => {
                return (
                  <TableRow
                    variantsData={variantsData}
                    key={i}
                    customer={massageTableData(customer)}
                    i={i}
                    index={i}
                    changeEditStatus={changeEditStatus}
                    logoUpload={logoUpload}
                    removeImage={removeImage}
                    categories={categories}
                    countriesList={countriesList}
                    measuringUnits={measuringUnits}
                  />
                );
              })}
          </tbody>
        </table>
      ) : ( */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Button onClick={downloadExcel}>Download Validated Excel</Button>
      </div>
      {/* )} */}
    </div>
  );
};

export default Table;
